<script>
import axios from "axios";

export default {
	name: "MobileRestorePasswordForm",
	template: `<slot v-bind="self" />`,

	props: {
		url: String,
	},

	data() {
		return {
			email: {
				value: ""
			},
			emailError: false,
			showSuccessMessage: false
		}
	},

	computed: {
		self: function() {
			return this;
		},

		formValid: function() {
			return this.email.value.length > 0 && this.isEmail(this.email.value)
		},
	},

	methods: {
		checkForm: function(e) {
			if (this.formValid) {
				e.preventDefault()

				axios.request({
					response_type: "json",
					headers: {
						"X-Requested-With": "XMLHttpRequest",
						"X-CSRF-Token": document
							.querySelector('meta[name="csrf-token"]')
							.getAttribute("content")
					},
					method: "POST",
					url: this.url,
					data: { user: { email: this.email.value } }
				}).then((response) => {
					if (response.data.success) {
						this.emailError = false
						this.showSuccessMessage = true
						return true
					} else {
						this.emailError = true
						e.preventDefault()
					}
				})
			} else {
				this.emailError = true
				e.preventDefault()
			}
		},

		isEmail: function(email) {
			const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		}
	}
}
</script>
