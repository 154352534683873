import { createApp } from 'vue';
import i18n from '../static/locales/i18n.js';

import MobileRestorePasswordForm from "../components/mobile/shared/RestorePasswordForm.vue";
import WelcomePartners from "../components/mobile/Welcome/WelcomePartners.vue";
import WhyEduson from "../components/mobile/Welcome/WhyEduson.vue";

document.addEventListener('DOMContentLoaded', () => {
	Array.from(document.getElementsByClassName('vjs-mobile-start')).forEach((el) => {
		const app = createApp();
		app.use(i18n);

		app.component('mobile-restore-password-form', MobileRestorePasswordForm);
		app.component('welcome-partners', WelcomePartners);
		app.component('why-eduson', WhyEduson);

		app.mount(el);

		return app;
	});

	const showPopup = function() {
		let target = this.dataset.targetPopup;
		let popup = document.querySelector(target);

		popup.classList.remove('hide');
		popup.classList.add('showing');
		if (popup.style.display == 'none') popup.style.display = 'block';
	}

	Array.from(document.getElementsByClassName('popup-trigger')).forEach(element => {
		element.addEventListener('click', showPopup);
		element.addEventListener('touchend', showPopup);
	})
});

